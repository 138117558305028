<template>
	<CreateDialog :dialog="createDialog" v-on:close="createDialog = false">
		<template v-slot:title>
			<span>{{ pageTitle() }}</span>
		</template>
		<template v-slot:body>
			<v-sheet style="height: calc(100vh - 188px)">
				<v-row>
					<v-col md="12">
						<v-form v-if="createDialog" ref="milestoneForm">
							<v-row>
								<v-col md="9">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll custom-box-top-inner-shadow"
										style="position: relative"
									>
										<v-row class="px-4">
											<v-col md="3">
												<label for="name" class="btx-label mt-2">Name</label>
											</v-col>
											<v-col md="9">
												<TextInput
													hide-details
													placeholder="Name"
													v-model="milestone.phase"
													:disabled="pageLoading"
													:loading="pageLoading"
													:rules="[vrules.required(milestone.phase, 'Title')]"
													:class="{
														required: !milestone.phase,
													}"
													id="name"
												></TextInput>
											</v-col>
											<v-col md="3">
												<label for="description" class="btx-label mt-5">Description</label>
											</v-col>
											<v-col md="9">
												<TextAreaInput
													:disabled="pageLoading"
													:loading="pageLoading"
													id="description"
													placeholder="Description"
													v-model="milestone.description"
												>
												</TextAreaInput>
											</v-col>
											<v-col md="3">
												<label for="date" class="btx-label mt-4 required">Date</label>
											</v-col>
											<v-col md="3">
												<DatePicker
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="date"
													v-model="milestone.date"
													:rules="[vrules.required(milestone.date, 'Date')]"
													:class="{
														required: !milestone.date,
													}"
												></DatePicker>
											</v-col>
											<v-col class="text-right" md="3">
												<label for="reminder_date" class="btx-label mt-4">Reminder Date</label>
											</v-col>
											<v-col md="3">
												<DatePicker
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="reminder_date"
													v-model="milestone.reminder_date"
												></DatePicker>
											</v-col>
											<v-col class="" md="3">
												<label for="type" class="btx-label mt-4">Amount Type</label>
											</v-col>
											<v-col md="3">
												<AutoCompleteInput
													hide-details
													:items="amountTypes"
													placeholder="Payment Type"
													v-model="payment_method"
													:disabled="pageLoading"
													@change="amountTypeChange"
													id="type"
													:loading="pageLoading"
												>
												</AutoCompleteInput>
											</v-col>

											<v-col class="text-right" md="3">
												<label v-if="payment_method === 1" for="payment_amount" class="btx-label mt-4 required"
													>Milestone Amount</label
												>
												<label v-else for="payment_amount" class="btx-label mt-4 required">Percentage</label>
											</v-col>
											<v-col md="3" class="d-flex flex-column">
												<div>
													<PriceInput
														v-if="payment_method === 1"
														:max="Number(projectBalance)"
														hideSpinButtons
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="payment_amount"
														type="number"
														placeholder="Milestone Amount"
														:class="{
															required: !milestone.payment_amount,
														}"
														:rules="[vrules.required(milestone.payment_amount, 'Milestone Amount')]"
														v-model.number="milestone.payment_amount"
														@keyup="changeMilestoneAmount"
													>
													</PriceInput>
													<TextInput
														v-else
														hide-details
														id="payment_amount"
														placeholder="Percentage"
														append-icon="mdi-percent"
														@keypress="percentageKeyup"
														@keyup="checkPercentage"
														v-model="milestone.percentage"
														:disabled="pageLoading"
														:loading="pageLoading"
														:rules="[
															vrules.required(milestone.percentage, 'Percentage'),
															vrules.lessThan(milestone.percentage, 'Percentage', 100),
														]"
														:class="{ required: !milestone.percentage }"
													>
													</TextInput>
													<div class="mt-1" v-if="payment_method === 2">
														<div class="d-flex">
															<div style="width: 120px" class="font-level-0-bold">Milestone Amount :</div>
															<div class="ml-2 font-level-0" v-if="calculatedMilestoneAmountByPercentage">
																{{ formatMoney(calculatedMilestoneAmountByPercentage) }}
															</div>
														</div>
													</div>
													<div class="mt-1">
														<div class="d-flex">
															<div style="width: 120px" class="font-level-0-bold">Project Balance :</div>
															<div class="ml-2 font-level-0">
																<!-- <ShowPrice
																	:object="projectDetail"
																	object-key="project_balance"
																	label="Project Balance"
																>
																</ShowPrice> -->
																<span v-if="payment_method == 1">
																	{{ formatMoney(projectBalance - milestone.payment_amount) }}</span
																>
																<span v-else>
																	{{ formatMoney(projectBalance - calculatedMilestoneAmountByPercentage) }}</span
																>
															</div>
														</div>
													</div>
													<div class="mt-1">
														<div class="d-flex">
															<div style="width: 120px" class="font-level-0-bold">Project Value :</div>
															<div class="ml-2 font-level-0">
																<ShowPrice
																	:object="projectDetail"
																	object-key="project_value"
																	label="Project Balance"
																>
																</ShowPrice>
															</div>
														</div>
													</div>
												</div>
											</v-col>
											<v-col md="3">
												<label for="attachment" class="btx-label mt-4">Attachment</label>
											</v-col>
											<v-col md="9">
												<FileUpload id="attachment" v-model="milestone.attachment"></FileUpload>
											</v-col>
											<v-col md="3"> <label for="quotation" class="btx-label mt-4">Quotations</label> </v-col>
											<v-col md="9">
												<!-- <AutoCompleteInput
													multiple
													hide-details
													:items="quotationList"
													:disabled="pageLoading"
													item-text="titleBarcode"
													item-value="id"
													hide-selected
													:loading="pageLoading"
													id="quotation"
													placeholder="quotations"
													v-model="milestone.quotations"
												>
												</AutoCompleteInput> -->
												<v-autocomplete
													id="quotations"
													:loading="pageLoading"
													:items.sync="quotationList"
													item-text="quotationText"
													item-value="id"
													hide-details
													multiple
													dense
													outlined
													class="pt-0 mt-3"
													v-model="milestone.quotations"
													placeholder="Select Quotations"
												>
													<template v-slot:item="data">
														<v-list-item-content class="py-1">
															<v-list-item-title
																>{{ data.item.barcode }} {{ data.item.title }}
																<v-chip color="green" label small outlined
																	>${{ data.item.total }}</v-chip
																></v-list-item-title
															>
														</v-list-item-content>
													</template>
													<template v-slot:selection="{ item, index }">
														<span class="blue-grey-darken-2">
															{{ item.barcode
															}}<span
																style="padding: 2px"
																v-if="milestone.quotations.length > 1 && index + 1 !== milestone.quotations.length"
																>,</span
															></span
														>
													</template>
												</v-autocomplete>
											</v-col>
											<!-- <v-col md="3">
												<label for="payment_remaining" class="btx-label mt-4">Remaining</label>
											</v-col>
											<v-col md="9">
												<PriceInput hide-details :disabled="pageLoading" :loading="pageLoading"
													id="payment_remaining" type="number" placeholder="Payment Remaining"
													v-model.number="milestone.payment_remaining">
												</PriceInput>
											</v-col> -->
										</v-row>
									</perfect-scrollbar>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				v-on:click="(createDialog = false), $router.go(-1)"
			>
				Close
			</v-btn>
			<v-btn class="white--text" depressed color="blue darken-4" tile @click="updateOrCreate">
				Save
			</v-btn>
		</template>
	</CreateDialog>
</template>
<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import PriceInput from "@/view/components/PriceInput";
import FileUpload from "@/view/components/FileUpload";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import CreateDialog from "@/view/components/CreateDialog";
// import TooltipQuestion from "@/view/components/TooltipQuestion";
import ShowPrice from "@/view/components/ShowPrice";
import {
	updateMilestone,
	getQuotationAndPurchaseOrder,
	CreateMilestone,
	getMilestoneByUUID,
} from "@/core/lib/project.lib";
import { GetProject } from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { getData, saveData /* deleteData */ } from "@/core/services/local.service";
export default {
	name: "create-milestone",
	title: "Milestone-create",
	components: {
		// Dialog,
		PriceInput,
		TextInput,
		FileUpload,
		AutoCompleteInput,
		CreateDialog,
		DatePicker,
		TextAreaInput,
		ShowPrice,
		// TooltipQuestion,
	},
	props: {
		addDialog: {
			type: Boolean,
			default: false,
		},
		project: {
			type: Object,
			default: () => {},
		},
		milestoneUUID: {
			type: String,
		},
	},
	data() {
		return {
			pageLoading: false,
			uuid: null,
			createDialog: true,
			projectBalance: null,
			quotationList: [],
			payment_method: 1,
			amountTypes: [],
			milestoneDetail: null,
			projectDetail: null,
			milestone: {
				date: null,
				phase: null,
				attachment: [],
				percentage: null,
				description: null,
				invoice_date: null,
				reminder_date: null,
				quotations: [],
				amount_raised: null,
				payment_amount: null,
				invoice_raised: null,
				payment_received: null,
				// payment_remaining: null,
			},
		};
	},
	computed: {
		...mapGetters(["errors"]),
		calculatedMilestoneAmount() {
			if (this.milestone.percentage > 0) {
				const amount = this.projectDetail?.project_balance / this.milestone.percentage;
				let a = amount * 100;
				return a.toFixed(2);
			} else {
				return 0;
			}
		},
		calculatedMilestoneAmountByPercentage() {
			return Number(this.milestone.percentage / 100) * Number(this.projectDetail?.project_value);
		},
	},
	methods: {
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.milestoneForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.milestoneForm.validate()) {
				return false;
			}
			try {
				const formData = {
					project: _this.projectDetail.id,
					phase: _this.milestone.phase,
					date: _this.milestone.date,
					description: _this.milestone.description,
					amount:
						_this.payment_method === 1
							? _this.milestone.payment_amount
							: Number(_this.milestone.percentage),
					progress: _this.milestone.percentage,
					quotations: _this.milestone.quotations,
					amount_type: _this.payment_method,
					reminder_date: _this.milestone.reminder_date,
					files: _this.milestone.attachment,
					amount_raised: _this.milestone.amount_raised,
					invoice_date: _this.milestone.invoice_date,
					invoice_raised: _this.milestone.invoice_raised,
					payment_received: _this.milestone.payment_received,
					// remaining: _this.milestone.payment_remaining,
				};

				_this.pageLoading = true;
				if (_this.uuid) {
					const data = await updateMilestone(_this.$route.query.mileUUID, formData);
					_this.$emit("success", data);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Milestone has been update." },
					]);
				} else {
					const data = await CreateMilestone(formData);
					_this.$emit("success", data);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Milestone has been create." },
					]);
				}

				_this.uuid = null;
				_this.milestone = {
					project: null,
					phase: null,
					date: null,
					description: null,
					amount: null,
					progress: null,
					// quotation: null,
					reminder_date: null,
					files: null,
					amount_raised: null,
					invoice_raised: null,
					payment_received: null,
					remaining: null,
					quotations: [],
				};
				_this.goBack();
			} catch (error) {
				if (error.data.message) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error.data.message }]);
				} else {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
			} finally {
				_this.pageLoading = false;
			}
		},
		pageTitle() {
			if (this.$route.query.mileUUID) {
				return "Edit Milestone";
			}
			return "Create Milestone";
		},
		async getQuotationAndPO(id) {
			try {
				const data = await getQuotationAndPurchaseOrder(id);
				this.quotationList = [...data.quotations];
				this.quotationList = this.quotationList.map((quotation) => {
					if (quotation.project === this.projectDetail.id && quotation.status_value == "approved") {
						// disabling quotation which already selected only time of update
						if (this.$route.query.mileUUID) {
							if (this.milestoneDetail.quotations_to_disable.includes(quotation.id)) {
								quotation.disabled = true;
							}
						}
						quotation.titleBarcode = `${quotation.barcode}-$${quotation.total}-${quotation.title}`;
						return quotation;
					}
				});
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async getProject() {
			try {
				// this.pageLoading = true;
				const project = await GetProject(this.$route.params.uuid);
				this.projectDetail = project[0];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		setProjectBalance() {
			if (this.$route.query.mileUUID) {
				this.projectBalance =
					Number(this.projectDetail?.project_balance) + Number(this.milestoneDetail.amount);
			} else {
				this.projectBalance = Number(this.projectDetail?.project_balance);
			}
		},
		async getMilestone() {
			try {
				this.pageLoading = true;
				const milestone = await getMilestoneByUUID(this.$route.query.mileUUID);
				this.milestoneDetail = milestone[0];
				return milestone[0];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		percentageKeyup($event) {
			if (String(this.milestone.percentage).includes(".")) {
				let percentage = String(this.milestone.percentage).split(".");
				if (percentage[1].length > 1) {
					$event.preventDefault();
				}
			}
			if (
				this.projectBalance <
				Number(this.milestone.percentage / 100) * Number(this.projectDetail?.project_value)
			) {
				this.milestone.percentage = 0;
			}
			const percentage = this.milestone.percentage + Number($event.key);
			if (percentage > 100) {
				$event.preventDefault();
			}
		},
		checkPercentage() {
			if (
				this.projectBalance <
				Number(this.milestone.percentage / 100) * Number(this.projectDetail?.project_value)
			) {
				this.milestone.percentage = 0;
				const formErrors = [
					{
						message: "Percentage Can not be greater then remaining percentage",
						model: true,
						timeout: 2000,
					},
				];
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
			}
		},
		milestoneAmountChange() {
			if (this.milestone.payment_amount > this.projectDetail.cost) {
				this.milestone.payment_amount = this.projectDetail.cost;
			}
		},
		changeMilestoneAmount() {
			if (this.milestone?.payment_amount > this.projectBalance) {
				this.milestone.payment_amount = this.projectBalance;
			}
		},
		amountTypeChange() {
			saveData("amount_method_type", this.payment_method);
		},
	},
	async mounted() {
		await this.$store.dispatch(SET_LOCAL_DB).then((data) => {
			this.amountTypes = data.amount_types;
		});
		let amount_method_type = getData("amount_method_type");
		if (!amount_method_type) {
			this.payment_method = 1;
		} else {
			this.payment_method = amount_method_type;
		}

		await this.getProject();
		if (this.$route.query?.mileUUID) {
			this.uuid = this.$route.query?.mileUUID;
			const milestone = await this.getMilestone();
			const quotation = milestone.quotations.map((ele) => {
				return ele.id;
			});

			milestone.quotations = quotation;
			this.milestone = {
				date: milestone.date,
				phase: milestone.phase,
				payment_amount: milestone.amount,
				percentage: milestone.progress ? milestone.progress : null,
				description: milestone.description,
				invoice_date: milestone.invoice_date,
				reminder_date: milestone.reminder_date,
				// amount_raised: milestone.amount_raised ? milestone.amount_raised : null,

				invoice_raised: milestone.invoice_raised ? milestone.invoice_raised : null,
				quotations: milestone.quotations ? milestone.quotations : null,
				payment_received: milestone.payment_received ? milestone.payment_received : null,
				// payment_remaining: milestone.remaining ? milestone.remaining : null,
			};
			this.payment_method = Number(milestone.amount_type);
		}
		await this.getQuotationAndPO(this.projectDetail.id);
		this.setProjectBalance();
	},
};
</script>
